import React, { useEffect, useCallback, useState, useReducer } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import ApiHelper from 'api/helper';
import { useDeepCompareEffect } from 'lib/useDeepCompare';
import Button from 'components/Button';
import DesignTitleModal from 'pages/Design/components/DesignTitleModal';
import MissionTab from './components/MissionTab';
import StrategyTab from './components/StrategyTab';
import DesignCriteriaTab from './components/DesignCriteriaTab';
import ConfigurationTab from './components/ConfigurationTab';
import FunctionsTab from './components/FunctionsTab';
import UnitTab from './components/UnitTab';
import FunctionUnitAllocation from './components/FunctionUnitAllocation';
import AuthorityTab from './components/AuthorityTab';
import OrgChartTab from './components/OrgChartTab';
import RoleTab from './components/RoleTab';
import RoleALlocationTab from './components/RoleAllocationTab';
import { generateuniqueIshId, arrayReducer, raciReducer, matrixReducer } from 'lib/helper';
import EvaluationTab from './components/EvaluationTab';

const emptyBluprintObject = {
  archetype: {
    classification: '',
    strategy_challenge: '',
    classification_recommendation: '',
  },
  orientation: {
    orientation: '',
    strategy_challenge: '',
    orientation_recommendation: '',
  },
}

const classificationOptions = [
  {id: 'cost_center', label: 'Cost Center'},
  {id: 'revenue_center', label: 'Revenue Center'},
  {id: 'profit_center', label: 'Profit Center'},
  {id: 'investment_center', label: 'Investment Center'},
  {id: 'service_center', label: 'Service Center'},
  {id: 'support_center', label: 'Support Center'},
];


const DefineIndex = (props) => {
  const [currentClient, setCurrentClient] = useState(null);
  const [initialDataLoaded, setInitDataLoaded] = useState(false);
  const [activeTab, setActiveTab] = useState('EvaluationTab');
  const [defineModuleData, setDefineModuleData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);
  
  const [name, setName] = useState('');
  const [website, setWebsite] = useState('');
  const [businessDetails, setBusinessDetails] = useState({
    industry:{industry:'',industry_group:'',sector:'',sub_industry:''},
    num_employees:''
  });
  const [missionStatement, setMissionStatement] = useState('');
  const [strategyArena, setStrategyArena] = useState('');
  const [strategyVehicle, setStrategyVehicle] = useState('');
  const [strategyDifferentiator, setStrategyDifferentiator] = useState('');
  const [strategyStaging, setStrategyStaging] = useState('');
  const [strategyEconomicLogic, setStrategyEconomicLogic] = useState('');
  const [designCriteria, setDesignCriteria] = useReducer(arrayReducer, []);
  const [blueprint, setBlueprint] = useState(emptyBluprintObject);
  const [functions, setFunctions] = useReducer(arrayReducer, []);
  const [organizationUnits, setOrganizationUnits] = useReducer(arrayReducer, []);
  const [functionUnitAllocation, setFunctionUnitAllocation] = useReducer(arrayReducer, []);
  const [mandates, setMandates] = useReducer(arrayReducer, []);
  const [authority, setRaciMatrix] = useReducer(raciReducer, []);
  const [roles, setRoles] = useReducer(arrayReducer, []);
  const [roleAllocation, setRoleAllocation] = useReducer(matrixReducer, []);
  const [orgFte, setOrgFte] = useState(100);
  const [designCriteriaEvaluation, setDesignCriteriaEvaluation] = useReducer(arrayReducer, []);
  const navigate = useNavigate();

  useDeepCompareEffect(() => {
    if (!loading && currentClient === props.currentClient) {
      // This code runs when any of the dependencies have changed deeply, but only after the initial data has been loaded
      save(name, website, businessDetails, missionStatement, strategyArena, strategyVehicle, strategyDifferentiator, strategyStaging, strategyEconomicLogic, 
        designCriteria, functions, organizationUnits, functionUnitAllocation, mandates, authority, roles, roleAllocation, orgFte, designCriteriaEvaluation);
    }
  }, [name, website, businessDetails, missionStatement, strategyArena, strategyVehicle, strategyDifferentiator, strategyStaging, strategyEconomicLogic, 
    designCriteria, functions, organizationUnits, functionUnitAllocation, mandates, authority, roles, roleAllocation, orgFte, designCriteriaEvaluation]);

  useEffect(() => {
    if (!props.currentClient) return;

    ApiHelper.get(`/api/define/?client=${props.currentClient}`).then((defineObjectsList) => {
      const define_queryset = defineObjectsList.data[0];
      setDefineModuleData(define_queryset);
      if (define_queryset) {
        setName(define_queryset.business_name);
        setWebsite(define_queryset.business_website);
        setBusinessDetails(JSON.parse(define_queryset.business_details));
        setMissionStatement(define_queryset.mission_statement);
        setStrategyArena(define_queryset.strategy_arena);
        setStrategyVehicle(define_queryset.strategy_vehicle);
        setStrategyDifferentiator(define_queryset.strategy_differentiators);
        setStrategyStaging(define_queryset.strategy_staging);
        setStrategyEconomicLogic(define_queryset.strategy_economic_logic);
        setDesignCriteria({ type: 'overwrite', payload: define_queryset.design_criteria ? JSON.parse(define_queryset.design_criteria) : [] });
        setBlueprint(define_queryset.blueprint ? JSON.parse(define_queryset.blueprint) : {});
        setFunctions({ type: 'overwrite', payload: define_queryset.functions ? JSON.parse(define_queryset.functions) : [] });
        setOrganizationUnits({ type: 'overwrite', payload: define_queryset.organization_units ? JSON.parse(define_queryset.organization_units) : [] });
        setFunctionUnitAllocation({ type: 'overwrite', payload: define_queryset.function_unit_allocation ? JSON.parse(define_queryset.function_unit_allocation) : [] });
        setMandates({ type: 'overwrite', payload: define_queryset.mandates ? JSON.parse(define_queryset.mandates) : [] });
        setRaciMatrix({ type: 'overwrite', payload: define_queryset.raci_matrix ? JSON.parse(define_queryset.raci_matrix) : [] });
        setRoles({ type: 'overwrite', payload: define_queryset.roles ? JSON.parse(define_queryset.roles) : [] });
        setRoleAllocation({ type: 'overwrite', payload: define_queryset.role_allocation ? JSON.parse(define_queryset.role_allocation) : [] });
        setOrgFte(define_queryset.target_org_fte);
        setDesignCriteriaEvaluation({ type: 'overwrite', payload: define_queryset.design_criteria_evaluation ? JSON.parse(define_queryset.design_criteria_evaluation) : [] });
        setInitDataLoaded(true);
      } else {
        setName('');
        setWebsite('');
        setBusinessDetails(
          {
            industry:{industry:'',industry_group:'',sector:'',sub_industry:''},
            revenue:{revenue:'',currency:{name: '', currencyCode: '', countryCode: ''}},
            num_employees:''
          }
        );
        setMissionStatement('');
        setStrategyArena('');
        setStrategyVehicle('');
        setStrategyDifferentiator('');
        setStrategyStaging('');
        setStrategyEconomicLogic('');
        setDesignCriteria({ type: 'overwrite', payload: [] });
        setBlueprint(emptyBluprintObject);
        setFunctions({ type: 'overwrite', payload: [] });
        setOrganizationUnits({ type: 'overwrite', payload: [] });
        setFunctionUnitAllocation({ type: 'overwrite', payload: [] });
        setMandates({ type: 'overwrite', payload: [] });
        setRaciMatrix({ type: 'overwrite', payload: [] });
        setRoles({ type: 'overwrite', payload: [] });
        setRoleAllocation({ type: 'overwrite', payload: [] });
        setOrgFte(100);
        setDesignCriteriaEvaluation({ type: 'overwrite', payload: [] });
      }
      setCurrentClient(props.currentClient);
      setLoading(false);
    });
  }, [props.currentClient]);

  const save = useCallback(async (name, website, businessDetails, missionStatement, strategyArena, strategyVehicle, strategyDifferentiator, strategyStaging, 
    strategyEconomicLogic, designCriteria, functions, organizationUnits, functionUnitAllocation, mandates, raciMatrix, roles, roleAllocation, orgFte, designCriteriaEvaluation) => {
    const apiPayload = {
      id: defineModuleData?.id,
      client: props.currentClient,
      
      business_name: name,
      business_website: website,
      business_details: JSON.stringify(businessDetails),
      mission_statement: missionStatement,
      strategy_arena: strategyArena,
      strategy_vehicle: strategyVehicle,
      strategy_differentiator: strategyDifferentiator,
      strategy_staging: strategyStaging,
      strategy_economic_logic: strategyEconomicLogic,
      design_criteria: JSON.stringify(designCriteria),
      functions: JSON.stringify(functions),
      organization_units: JSON.stringify(organizationUnits),
      function_unit_allocation: JSON.stringify(functionUnitAllocation),
      mandates: JSON.stringify(mandates),
      raci_matrix: JSON.stringify(raciMatrix),
      roles: JSON.stringify(roles),
      role_allocation: JSON.stringify(roleAllocation),
      target_org_fte: orgFte,
      design_criteria_evaluation: JSON.stringify(designCriteriaEvaluation),
    };

    if (defineModuleData?.id) {
      await ApiHelper.patch(`/api/define/${defineModuleData.id}/`, {
        data: apiPayload,
      });
      setDefineModuleData({
        ...apiPayload
      });

      return {
        ...apiPayload
      };
    } else {
      try {
        const { data: st } = await ApiHelper.post('/api/define/', {
          data: apiPayload,
        });
      
        setDefineModuleData(st);
      return st;
      } catch (e) {        
        console.log(e);
      }
    }
  }, [props.currentClient, name, website, businessDetails, missionStatement, 
    strategyArena, strategyVehicle, strategyDifferentiator, strategyStaging, strategyEconomicLogic, 
    designCriteria, functions, organizationUnits, functionUnitAllocation, mandates, authority, roles, roleAllocation, orgFte, designCriteriaEvaluation]);

  const saveAndChangeTab = useCallback(
    (tab) => {
      save(name, website, businessDetails, missionStatement, strategyArena, strategyVehicle, strategyDifferentiator, strategyStaging, strategyEconomicLogic, 
        designCriteria, functions, organizationUnits, functionUnitAllocation, mandates, authority, roles, roleAllocation, orgFte, designCriteriaEvaluation).then(() => setActiveTab(tab));
    },
    [save, name, website, businessDetails, missionStatement, strategyArena, strategyVehicle, strategyDifferentiator, strategyStaging, strategyEconomicLogic, 
      designCriteria, functions, organizationUnits, functionUnitAllocation, mandates, authority, roles, roleAllocation, orgFte, designCriteriaEvaluation]
  );

  const onModalToggle = () => {
    setShowModal(!showModal);
  }

  const navigateToDescribeModule = () => {
    save(name, website, businessDetails, missionStatement, strategyArena, strategyVehicle, strategyDifferentiator, strategyStaging, strategyEconomicLogic, 
      designCriteria, functions, organizationUnits, functionUnitAllocation, mandates, authority, roles, roleAllocation, orgFte, designCriteriaEvaluation).then(() => {
      navigate('/describe/', {
        state: {
          business_name: name,
          business_website: website,
        },
      });
    });
  };

  const createDesign = (title) => {
    ApiHelper.post('/api/design-from-define/', {
      data: {
        title,
        define_id: defineModuleData.id,
        client: props.currentClient,
      },
    }).then((res) => {
      navigate('/design/org/' + res.data.id);
    });
  }


  if (!+props.currentClient) {
    return (
      <div style={{ textAlign: 'center' }} className="py-4">
        <h3>Select the organization from the dropdown at the top right of your screen to select which organization you want to work on</h3>
      </div>
    );
  }

  const addNewRole = (
    roleType = '',
    name = '',
    abbreviation = '',
    emoji = '',
    purpose = '',
    responsibilities = '',
  ) => {
    const unitNumber = Array.isArray(roles) ? roles.length + 1 : 1;
    const newRow = {
      id: generateuniqueIshId(),
      name: name || `Role ${unitNumber}`,
      abbreviation: abbreviation || `R_${unitNumber}`,
      emoji: emoji,
      purpose: purpose,
      responsibilities: responsibilities,
      roleType: roleType,
    };
    setRoles({ type: 'prepend', payload: [newRow] });
    return newRow;
  };
  

  const removeRole = (id) => {
    setRoles({type: 'remove', payload: id });
  }

  return (
    <div className="strategy-index">
      <div
        className="content__header py-4 d-lg-flex align-items-center justify-content-between">
        <h3 className="ellipsis">Define your target organization</h3>
        <div className='strategy-tab-modal'>
          <Button
            className="button me-3 mb-3 w-auto"
            text="Simulate target organization"
            onClick={onModalToggle}
          />
          <Button
            className="button me-3 mb-3 w-auto"
            text="Describe current organization"
            onClick={navigateToDescribeModule}
          />
        </div>
      </div>
      <div className="card__group">
        <div className="card">
          <div className="card__content-wrapper">
            <Nav tabs>
              <NavItem>
                <NavLink
                  active={activeTab === 'BusinessProfileTab'}
                  onClick={() => saveAndChangeTab('BusinessProfileTab')}
                  className="strategy-tab">
                  Mission
                </NavLink>
              </NavItem>
             <NavItem>
              <NavLink
                active={activeTab === 'StrategyTab'}
                onClick={() => saveAndChangeTab('StrategyTab')}
                className="strategy-tab">
                Strategy
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={activeTab === 'DesignCriteriaTab'}
                onClick={() => saveAndChangeTab('DesignCriteriaTab')}
                className="strategy-tab">
                Design Criteria
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={activeTab === 'ConfigurationTab'}
                onClick={() => saveAndChangeTab('ConfigurationTab')}
                className="strategy-tab">
                Configuration
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={activeTab === 'FunctionsTab'}
                onClick={() => saveAndChangeTab('FunctionsTab')}
                className="strategy-tab">
                Functions
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={activeTab === 'UnitTab'}
                onClick={() => saveAndChangeTab('UnitTab')}
                className="strategy-tab">
                Units
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={activeTab === 'FunctionUnitAllocation'}
                onClick={() => saveAndChangeTab('FunctionUnitAllocation')}
                className="strategy-tab">
                Function Allocation
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={activeTab === 'AuthorityTab'}
                onClick={() => saveAndChangeTab('AuthorityTab')}
                className="strategy-tab">
                Authority
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={activeTab === 'RoleTab'}
                onClick={() => saveAndChangeTab('RoleTab')}
                className="strategy-tab">
                Roles
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={activeTab === 'StaffingTab'}
                onClick={() => saveAndChangeTab('StaffingTab')}
                className="strategy-tab">
                Role Allocation
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={activeTab === 'StructureTab'}
                onClick={() => saveAndChangeTab('StructureTab')}
                className="strategy-tab">
                Org Chart
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={activeTab === 'EvaluationTab'}
                onClick={() => saveAndChangeTab('EvaluationTab')}
                className="strategy-tab">
                Evaluation
              </NavLink>
            </NavItem>
            </Nav>
            
            <TabContent activeTab={activeTab}>
              <TabPane tabId="BusinessProfileTab">
                <MissionTab
                  client_id={props.currentClient}
                  name={name}
                  setName={setName}
                  website={website}
                  setWebsite={setWebsite}
                  businessDetails={businessDetails}
                  setBusinessDetails={setBusinessDetails}
                  missionStatement={missionStatement}
                  setMissionStatement={setMissionStatement}
                />
              </TabPane>
              <TabPane tabId="StrategyTab">
                <StrategyTab
                  client={props.currentClient}
                  name={name}
                  website={website}
                  businessDetails={businessDetails}
                  missionStatement={missionStatement}
                  strategyArena={strategyArena}
                  setStrategyArena={setStrategyArena}
                  strategyVehicle={strategyVehicle}
                  setStrategyVehicle={setStrategyVehicle}
                  strategyDifferentiator={strategyDifferentiator}
                  setStrategyDifferentiator={setStrategyDifferentiator}
                  strategyStaging={strategyStaging}
                  setStrategyStaging={setStrategyStaging}
                  strategyEconomicLogic={strategyEconomicLogic}
                  setStrategyEconomicLogic={setStrategyEconomicLogic}
                />
              </TabPane>
              <TabPane tabId="DesignCriteriaTab">
                <DesignCriteriaTab 
                  client={props.currentClient}
                  designCriteria={designCriteria}
                  setDesignCriteria={setDesignCriteria}
                  name={name}
                  website={website}
                  businessDetails={businessDetails}
                  missionStatement={missionStatement}
                  strategyArena={strategyArena}
                  strategyVehicle={strategyVehicle}
                  strategyDifferentiator={strategyDifferentiator}
                  strategyStaging={strategyStaging}
                  strategyEconomicLogic={strategyEconomicLogic}
                />
              </TabPane>
              <TabPane tabId="ConfigurationTab">
                <ConfigurationTab 
                  client={props.currentClient}
                  name={name}
                  website={website}
                  businessDetails={businessDetails}
                  missionStatement={missionStatement}
                  strategyArena={strategyArena}
                  strategyVehicle={strategyVehicle}
                  strategyDifferentiator={strategyDifferentiator}
                  strategyStaging={strategyStaging}
                  strategyEconomicLogic={strategyEconomicLogic}
                  designCriteria={designCriteria}
                  blueprint={blueprint}
                  setBlueprint={setBlueprint}
                />
              </TabPane>
              <TabPane tabId="FunctionsTab">
                <FunctionsTab 
                  client={props.currentClient}
                  name={name}
                  website={website}
                  businessDetails={businessDetails}
                  missionStatement={missionStatement}
                  strategyArena={strategyArena}
                  strategyVehicle={strategyVehicle}
                  strategyDifferentiator={strategyDifferentiator}
                  strategyStaging={strategyStaging}
                  strategyEconomicLogic={strategyEconomicLogic}
                  designCriteria={designCriteria}
                  blueprint={blueprint}
                  functions={functions}
                  setFunctions={setFunctions}
                />
              </TabPane>
              <TabPane tabId="UnitTab">
                <UnitTab 
                  client={props.currentClient}
                  organizationUnits={organizationUnits}
                  setOrganizationUnits={setOrganizationUnits}
                  functions={functions}
                  name={name}
                  website={website}
                  businessDetails={businessDetails}
                  missionStatement={missionStatement}
                  strategyArena={strategyArena}
                  strategyVehicle={strategyVehicle}
                  strategyDifferentiator={strategyDifferentiator}
                  strategyStaging={strategyStaging}
                  strategyEconomicLogic={strategyEconomicLogic}
                  designCriteria={designCriteria}
                  classificationOptions={classificationOptions}
                  roles={roles}
                  addNewRole={addNewRole}
                  removeRole={removeRole}
                  setRoleAllocation={setRoleAllocation}
                />
              </TabPane>
              <TabPane tabId="FunctionUnitAllocation">
                <FunctionUnitAllocation 
                  client={props.currentClient}
                  name={name}
                  website={website}
                  businessDetails={businessDetails}
                  missionStatement={missionStatement}
                  strategyArena={strategyArena}
                  strategyVehicle={strategyVehicle}
                  strategyDifferentiator={strategyDifferentiator}
                  strategyStaging={strategyStaging}
                  strategyEconomicLogic={strategyEconomicLogic}
                  designCriteria={designCriteria}
                  blueprint={blueprint}
                  functions={functions}
                  organizationUnits={organizationUnits}
                  functionUnitAllocation={functionUnitAllocation}
                  setFunctionUnitAllocation={setFunctionUnitAllocation}
                />
              </TabPane>
              <TabPane tabId="AuthorityTab">
                <AuthorityTab 
                  client={props.currentClient}
                  name={name}
                  website={website}
                  businessDetails={businessDetails}
                  missionStatement={missionStatement}
                  strategyArena={strategyArena}
                  strategyVehicle={strategyVehicle}
                  strategyDifferentiator={strategyDifferentiator}
                  strategyStaging={strategyStaging}
                  strategyEconomicLogic={strategyEconomicLogic}
                  designCriteria={designCriteria}
                  functions={functions}
                  organizationUnits={organizationUnits}
                  authorityMatrixData={authority}
                  setRaciMatrix={setRaciMatrix}
                />
              </TabPane>
              <TabPane tabId="RoleTab">
                <RoleTab 
                  client={props.currentClient}
                  name={name}
                  website={website}
                  businessDetails={businessDetails}
                  missionStatement={missionStatement}
                  strategyArena={strategyArena}
                  strategyVehicle={strategyVehicle}
                  strategyDifferentiator={strategyDifferentiator}
                  strategyStaging={strategyStaging}
                  strategyEconomicLogic={strategyEconomicLogic}
                  designCriteria={designCriteria}
                  organizationUnits={organizationUnits}
                  setOrganizationUnits={setOrganizationUnits}
                  functions={functions}
                  roles={roles}
                  setRoles={setRoles}
                  addNewRole={addNewRole}
                />
              </TabPane>
              <TabPane tabId="StaffingTab">
                <RoleALlocationTab 
                  initalDataLoaded={initialDataLoaded}
                  client={props.currentClient}
                  name={name}
                  website={website}
                  businessDetails={businessDetails}
                  missionStatement={missionStatement}
                  strategyArena={strategyArena}
                  strategyVehicle={strategyVehicle}
                  strategyDifferentiator={strategyDifferentiator}
                  strategyStaging={strategyStaging}
                  strategyEconomicLogic={strategyEconomicLogic}
                  designCriteria={designCriteria}
                  blueprint={blueprint}
                  functions={functions}
                  organizationUnits={organizationUnits}
                  setOrganizationUnits={setOrganizationUnits}
                  roles={roles}
                  roleAllocation={roleAllocation}
                  setRoleAllocation={setRoleAllocation}
                  orgFte={orgFte}
                  setOrgFte={setOrgFte}
                />
              </TabPane>
              <TabPane tabId="StructureTab">
                <OrgChartTab 
                  client={props.currentClient}
                  name={name}
                  website={website}
                  businessDetails={businessDetails}
                  missionStatement={missionStatement}
                  strategyArena={strategyArena}
                  strategyVehicle={strategyVehicle}
                  strategyDifferentiator={strategyDifferentiator}
                  strategyStaging={strategyStaging}
                  strategyEconomicLogic={strategyEconomicLogic}
                  designCriteria={designCriteria}
                  functions={functions}
                  organizationUnits={organizationUnits}
                  setOrganizationUnits={setOrganizationUnits}
                  raciMatrix={authority}
                  roles={roles}
                  roleAllocation={roleAllocation}
                  classificationOptions={classificationOptions}
                />
              </TabPane>
              <TabPane tabId="EvaluationTab">
                <EvaluationTab 
                  client={props.currentClient}
                  name={name}
                  website={website}
                  businessDetails={businessDetails}
                  missionStatement={missionStatement}
                  strategyArena={strategyArena}
                  strategyVehicle={strategyVehicle}
                  strategyDifferentiator={strategyDifferentiator}
                  strategyStaging={strategyStaging}
                  strategyEconomicLogic={strategyEconomicLogic}
                  designCriteria={designCriteria}
                  functions={functions}
                  organizationUnits={organizationUnits}
                  authority={authority}
                  roles={roles}
                  roleAllocation={roleAllocation}
                  designCriteriaEvaluation={designCriteriaEvaluation}
                  setDesignCriteriaEvaluation={setDesignCriteriaEvaluation}
                />
              </TabPane>
            </TabContent>
          </div>
        </div>
        <DesignTitleModal
          showModal={showModal}
          defaultTitle={name + ' ' + new Date().getDate() + '.' + (new Date().getMonth()+1) + '.' + new Date().getFullYear()}
          onConfirm={createDesign}
          onToggle={onModalToggle}
        />
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    currentClient: state.currentClient?.data
  };
};

export default connect(mapStateToProps)(DefineIndex);

import React, { useState, useEffect } from 'react';
import Loading from 'components/Loading';

const ClassificationDropdownEditor = (props) => {
  const { isLoading } = props.context;
  const { data } = props;
  const [value, setValue] = useState(props.value);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const handleChange = (event) => {
    const newValue = event.target.value;
    setValue(newValue);
    props.node.setDataValue(props.column.colId, newValue);
  };

  const rawOptions = props.options || props.column.colDef.cellEditorParams.options || [];
  const options = rawOptions.map(opt => (
    typeof opt === 'object' ? opt : { key: opt, label: opt }
  ));

  return (
    isLoading?.includes(data?.id) ? (
      <div className="loading-spin">
        <Loading size='small' />
      </div>
    ) : (
      <select value={value} onChange={handleChange} autoFocus>
        {options.map((option) => (
          <option key={option.key} value={option.label}>
            {option.label}
          </option>
        ))}
      </select>
    )
  );
};

export default ClassificationDropdownEditor;

import React from 'react';

const RatioBar = ({label1, label2, value1, value2, unit }) => {
  value1 = value1 > 0 ? value1 : 50;
  value2 = value2 > 0 ? value2 : 50;
  const total = value1 + value2;
  const ratio1 = total > 0 ? (value1 / total) * 100 : 50;
  const ratio2 = total > 0 ? (value2 / total) * 100 : 50;
  const textValue1 = Number(value1.toFixed(0)).toLocaleString('no-NO') || 0;
  const textValue2 = Number(value2.toFixed(0)).toLocaleString('no-NO') || 0;

  return (
    <div className="RatioBar">
      <div className="wrapper">
        <div className="bar-container">
          <div className="bar">
            <div className="bar-section bar-section1" style={{ width: `${ratio1}%` }}></div>
            <div className="bar-section bar-section2" style={{ width: `${ratio2}%` }}></div>
          </div>
        </div>
        <div className="labels">
            <div className="label">{`${label1}`}</div>
            <div className="label">{`${label2}`}</div>
        </div>
        <div className="labels">
          <div className="label">{`${textValue1} ${unit} ( ${ratio1 ? ratio1.toFixed(0) : 0}% )`}</div>
          <div className="label">{`${textValue2} ${unit} ( ${ratio2 ? ratio2.toFixed(0) : 0}% )`}</div>
        </div>
      </div>
    </div>
  );
};

export default RatioBar;

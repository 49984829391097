import React, { useEffect, useRef, useMemo } from 'react';

export const AgGridTextAreaEditor = (props) => {
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <textarea
      ref={inputRef}
      style={{ width: '100%', height: '100%', resize: 'vertical' }}
      value={props.value || ''}
      onChange={(e) => props.api.stopEditing(false, e.target.value)}
      onBlur={() => props.api.stopEditing()}
    />
  );
};

export const DefaultRichEditorConfig = {
    cellEditor: 'agLargeTextCellEditor',
      cellEditorPopup: true,
      cellEditorParams: {
        number: 500,
        rows: 15,
        cols: 100
      },
    };


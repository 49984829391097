import React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';

const chartSettingSpan = {
  xAxis: [
    {
      label: 'Total reports (FTE)',
    },
  ],
  width: 500,
  height: 400,
};

const chartSettingSalary = {
  xAxis: [
    {
      label: 'Salary in €',
    },
  ],
  width: 500,
  height: 400,
};

const SpansAndLayersChart = ({ data = [] }) => {
  if (!data.length) return <p>No data available</p>;

  return (
    <div style={{ display: 'flex', gap: '20px' }}>
      <div>
      <BarChart
          dataset={data}
          yAxis={[{ scaleType: 'band', dataKey: 'layer' }]}
          series={[
            { 
              dataKey: 'totalFTE', 
              label: 'Total Span per Organization Layer',
              labelColor: 'white',
              valueFormatter: (value) => (value || 0).toFixed(1),
              color: '#6295FF',
            }
          ]}
          layout="horizontal"
          grid={{ vertical: true }}
          borderRadius={5}
          barLabel="value"
          {...chartSettingSpan}
        />
        <BarChart
          dataset={data}
          yAxis={[{ scaleType: 'band', dataKey: 'layer' }]}
          series={[
            { 
              dataKey: 'avgFte', 
              label: 'Average Span of control per Organization Layer',
              valueFormatter: (value) => (value || 0).toFixed(1),
              color: '#6295FF',
            }
          ]}
          layout="horizontal"
          grid={{ vertical: true }}
          borderRadius={5}
          barLabel="value"
          {...chartSettingSpan}
        />
      </div>
      <div>
        <BarChart
          dataset={data}
          yAxis={[{ scaleType: 'band', dataKey: 'layer' }]}
          series={[
            { 
              dataKey: 'totalSalary', 
              label: 'Total Salary of employees per Organization Layer',
              labelColor: 'white',
              color: '#6295FF',
            }
          ]}
          layout="horizontal"
          grid={{ vertical: true }}
          borderRadius={5}
          barLabel={(item, context) => {
            return new Intl.NumberFormat('no-NO', { style: 'currency', currency: 'EUR' }).format(Number(item.value));
          }}
          {...chartSettingSalary}
        />
        <BarChart
          dataset={data}
          yAxis={[{ scaleType: 'band', dataKey: 'layer' }]}
          series={[
            { 
              dataKey: 'avgSalary', 
              label: 'Average Salary of employees per Organization Layer',
              color: '#6295FF',
           }
          ]}
          barLabel={(item, context) => {
            return new Intl.NumberFormat('no-NO', { style: 'currency', currency: 'EUR' }).format(Number(item.value));
          }}
          layout="horizontal"
          grid={{ vertical: true }}
          borderRadius={5}
          {...chartSettingSalary}
        />
      </div>
    </div>
  );
};

export default SpansAndLayersChart;

import React, { useCallback, useMemo, useEffect, useState, useReducer, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Button from 'components/Button';
import _, { result, split } from 'lodash';
import ExportToExcel from 'lib/ExportToExcel';
import { generateuniqueIshId, listReducer, transposeObject, sum } from 'lib/helper';
import { postSearchApi } from 'api/chat';
import Loading from 'components/Loading';
import LoadingCellRenderer from 'components/LoadingCellRenderer';
import ArrayCellRenderer from 'components/ArrayCellRenderer';
import DraggableListCellRenderer from 'components/DraggableListCellRenderer';
import { Table, baseColumnConfig } from 'components/Table';
import { DefaultRichEditorConfig } from 'components/Table/AgGridTextAreaEditor';
import EditableHeader from 'components/Table/EditableHeader';

const calculateAverage = (arr) => {
  const validValues = arr.filter((value) => typeof value === 'number' && !isNaN(value));
  return validValues.length ? validValues.reduce((a, b) => a + b, 0) / validValues.length : 0;
};

const EvaluationTab = (props) => {
  const { client, name, website, businessDetails, missionStatement, 
    strategyArena, strategyVehicle, strategyDifferentiator, 
    strategyStaging, strategyEconomicLogic, designCriteria, blueprint, 
    functions, organizationUnits, authority, roles, roleAllocation, designCriteriaEvaluation, setDesignCriteriaEvaluation } = props;

  const [isEvaluating, setIsEvaluating] = useState(false);
  const [isLoading, setIsLoading] = useReducer(listReducer, []);

  
  useEffect(() => {
    if (!designCriteria || designCriteria.length === 0) {
      return;
    }
    const designCriteriaIds = designCriteria.filter(dc => dc.row_type !== 'loadingRow').map(dc => dc.id);
    const orgDesignEvaluationDesignCriteriaIds = designCriteriaEvaluation.length > 0 ? designCriteriaEvaluation.map(designCriteria => designCriteria.id) : [];

    if (!_.isEqual(designCriteriaIds, orgDesignEvaluationDesignCriteriaIds)) {
      designCriteria.forEach(designCriteria => {
        if (!orgDesignEvaluationDesignCriteriaIds.includes(designCriteria.id)) {
          const newRow = {
            id: designCriteria.id,
            emoji: designCriteria.emoji,
            abbreviation: designCriteria.abbreviation,
            design_criteria: designCriteria.design_criteria,
            name: designCriteria.name,
            score1: 0,
            score2: 0,
            score3: 0,
            reasoning: '',
          }

          setDesignCriteriaEvaluation({type: 'extend', payload: [newRow]});
        }
      });

      orgDesignEvaluationDesignCriteriaIds.forEach(rowId => {
        if (!designCriteriaIds.includes(rowId)) {
          setDesignCriteriaEvaluation({type: 'remove', payload: {id: rowId}});
        }
      });
    }
  }, [designCriteria, designCriteriaEvaluation, setDesignCriteriaEvaluation]);

  const scoreCellStyleSetter = (params) => {
    const score = params.value;
    if (score <= 1) return { backgroundColor: '#B60000' }; // Blue for low score
    if (score <= 2) return { backgroundColor: '#c46205' };
    if (score <= 3) return { backgroundColor: '#d1cc0a' };
    if (score <= 4) return { backgroundColor: '#7edd10' };
    if (score <= 5) return { backgroundColor: '#21e817' }; // green for high score
    return {}; // default color
  } 

  const ScoreBarRenderer = (params) => {
    const score = params.value;
    const scorePercent = Math.min((score / 5) * 100, 100); // Scale 0-5 to 0-100%
    
    return (
      <div style={{ position: 'relative', width: '100%', height: '100%', backgroundColor: '#e0e0e0', borderRadius: '4px' }}>
        <div
          style={{
            width: `${scorePercent}%`,
            height: '100%',
            backgroundColor:
              score === undefined ? '#e0e0e0' :
              score <= 1 ? '#B60000' :
              score <= 2 ? '#c46205' :
              score <= 3 ? '#d1cc0a' :
              score <= 4 ? '#7edd10' :
              '#21e817', // Green for score 5
            borderRadius: '4px',
          }}
        ></div>
        {/*<span style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: '#fff', fontWeight: 'bold' }}>
          {score}
        </span>*/}
      </div>
    );
  };

  const scoreValueSetter = (params) => {
    const newValue = parseInt(params.newValue, 10);
    if (newValue >= 1 && newValue <= 5) {
      params.data[params.colDef.field] = newValue;
      return true;
    }
    return false; // Reject invalid values
  };

  const getRowStyle = useCallback((params) => {
    if (params.data.id === 'total') {
      return {
        backgroundColor: '#f0f8ff', // Light blue background for emphasis
        fontWeight: 'bold',
        fontSize: '1.1em', // Slightly larger font for readability
        color: '#333',
      };
    }
    return {};
  }, []);

  const updateColumnHeader = (colId, newHeaderName) => {
    setColumnDefs((prevDefs) =>
      prevDefs.map((col) =>
        col.field === colId ? { ...col, headerName: newHeaderName } : col
      )
    );
  };
  
  const [columnDefs, setColumnDefs] = useState([
    { headerName: 'ID', field: 'id', editable: false, hide: true },
    { headerName: 'Design Criteria', field: 'design_criteria', editable: false, initialWidth: 200, resizable: true, cellRendererFramework: LoadingCellRenderer, cellRendererParams: { isLoading }, flex: 3, rowDrag: true },
    {
      headerName: 'Evaluation score',
      field: 'score1',
      editable: true,
      singleClickEdit: true,
      initialWidth: 50,
      resizable: true,
      cellRendererFramework: ScoreBarRenderer,
      valueSetter: scoreValueSetter,
      cellRendererParams: { isLoading },
      flex: 0,
      headerComponentFramework: EditableHeader,
      headerComponentParams: { setColumnHeaderName: (newName) => updateColumnHeader('score1', newName) },
      cellStyle: {
        whiteSpace: 'normal',
        wordWrap: 'break-word',
        wordBreak: 'keep-all'
      },
    },
    /*{
      headerName: 'Scenario 2',
      field: 'score2',
      editable: true,
      singleClickEdit: true,
      initialWidth: 50,
      resizable: true,
      cellRendererFramework: ScoreBarRenderer,
      valueSetter: scoreValueSetter,
      cellRendererParams: { isLoading },
      flex: 1,
      headerComponentFramework: EditableHeader,
      headerComponentParams: { setColumnHeaderName: (newName) => updateColumnHeader('score2', newName) },
    },
    {
      headerName: 'Scenario 3',
      field: 'score3',
      editable: true,
      singleClickEdit: true,
      initialWidth: 50,
      resizable: true,
      cellRendererFramework: ScoreBarRenderer,
      valueSetter: scoreValueSetter,
      cellRendererParams: { isLoading },
      flex: 1,
      headerComponentFramework: EditableHeader,
      headerComponentParams: { setColumnHeaderName: (newName) => updateColumnHeader('score3', newName) },
    },*/
    { headerName: 'Reasoning', field: 'reasoning', 
      editable: true, initialWidth: 200, minHeight: 100, resizable: true, wrapText: true, autoHeight: true, 
      cellRendererFramework: LoadingCellRenderer, cellRendererParams: { isLoading }, flex: 6,
      cellStyle: {
        whiteSpace: 'normal',
        wordWrap: 'break-word',
        wordBreak: 'keep-all'
      },
      ...DefaultRichEditorConfig,
    },
  ]);  

  const rowData = useMemo(() => {
    return Array.isArray(designCriteriaEvaluation)
      ? designCriteriaEvaluation.map((row) => ({
          id: row.id,
          emoji: row.emoji,
          abbreviation: row.abbreviation,
          name: row.name,
          design_criteria: row.design_criteria,
          score1: row.score1,
          score2: row.score2,
          score3: row.score3,
          reasoning: row.reasoning,
        }))
      : [];
  }, [designCriteriaEvaluation]);

  const totalRow = useMemo(() => {
    if (!Array.isArray(designCriteriaEvaluation)) return null;
    
    return {
      id: 'total',
      emoji: '',
      abbreviation: '',
      name: 'Total',
      design_criteria: 'Total',
      score1: calculateAverage(designCriteriaEvaluation.map(row => row.score1)),
      score2: calculateAverage(designCriteriaEvaluation.map(row => row.score2)),
      score3: calculateAverage(designCriteriaEvaluation.map(row => row.score3)),
      reasoning: '',
    };
  }, [designCriteriaEvaluation]);
  
  const rowDataWithTotal = useMemo(() => {
    return totalRow ? [...rowData, totalRow] : rowData;
  }, [rowData, totalRow]);

  const evaluateOrganizationDesign = async () => {
    if (isEvaluating) return;
    roleAllocation.forEach((unit) => {setIsLoading({ type: 'add_to_list', payload: unit.id });});
    setIsEvaluating(true);

    const apiPayload = {
        'prompt_template': 'evaluate_organization_design',
        'client': client,
        'business_name': name ? name : '', 
        'business_website': website ? website : '',
        'mission_statement': missionStatement ? missionStatement : '',
        'industry': businessDetails.industry ? businessDetails.industry : '',
        'strategy_arena': strategyArena ? strategyArena : '',
        'strategy_vehicle': strategyVehicle ? strategyVehicle : '',
        'strategy_differentiators': strategyDifferentiator ? strategyDifferentiator : '',
        'strategy_staging': strategyStaging ? strategyStaging : '',
        'strategy_economic_logic': strategyEconomicLogic ? strategyEconomicLogic : '',
        'design_criteria': designCriteria ? designCriteria : [],
        'blueprint': blueprint ? blueprint : {},
        'functions': functions ? functions : [],
        'organization_units': organizationUnits ? organizationUnits : [],
        'roles': roles ? roles : [],
        'role_allocation': roleAllocation ? roleAllocation : [],
    };

    try {
      const apiResponse = await postSearchApi(apiPayload);
      const orgDesignEvaluationResponse = Array.isArray(apiResponse.message) ? apiResponse.message : [];

      try {
        setDesignCriteriaEvaluation({ type: 'overwrite', payload: orgDesignEvaluationResponse } );
        setIsEvaluating(false);
      } catch (error) {
          console.error('Error parsing API response:', error);
          setIsEvaluating(false);
      }

    } catch (error) {
        console.error('Error evaluating organization design:', error);
    }
    setIsLoading({ type: 'overwrite', payload: [] });
    setIsEvaluating(false);
  };

  const validateEvaluationScore = (value) => {
    if (!value) return true;
    const number = parseFloat(value);
    return !isNaN(number) && number > 0;
  };
  
  return (
    <div className="define-tab">
      <div className="function-tab-header">
      </div>
      
      <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
        <button
            onClick={() => evaluateOrganizationDesign()}
            disabled={isEvaluating}
            className="button"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            Evaluate Organization Design
            {isEvaluating ? (
              <Loading style={{ marginLeft: 'auto', height: '35px' }} />
            ) : (
              <span className="material-symbols-outlined">neurology</span>
            )}
          </button>
        <ExportToExcel 
          buttonLabel='Export Design evaluation' 
          data={designCriteriaEvaluation} 
          fileName={'Design Evaluation from Reconfig' + name}
          sheetName={'Design Evaluation' + name}
          fieldsToExport={['id', 'name', 'abbreviation', 'score1', 'score2', 'score3', 'reasoning']}
          className="button"
        />
      </div>
      <div className="mt-6" style={{'margin': '10px'}}>
        <h2>Evaluation</h2>
      </div>
      <Table
        listTitle="Roles"
        list={designCriteriaEvaluation}
        setList={setDesignCriteriaEvaluation}
        columnDefs={columnDefs}
        rowData={rowDataWithTotal}
        isLoading={isLoading}
        getRowStyle={getRowStyle}
      />
    </div>
  );
}

export default EvaluationTab;

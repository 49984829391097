import React, { useCallback, useMemo, useEffect, useState, useReducer, useRef } from 'react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import { DefaultRichEditorConfig } from 'components/Table/AgGridTextAreaEditor';

import Button from 'components/Button';
import _ from 'lodash';
import { Modal, ModalBody, ModalHeader, Col, Row } from 'reactstrap';
import ExportToExcel from 'lib/ExportToExcel';
import SVG from 'components/SVG';
import AggridContextMenu from 'components/AggridContextMenu';
import Loading from 'components/Loading';
import CSVWizard from 'pages/Survey/components/CSVWizard';
import { generateuniqueIshId, listReducer } from 'lib/helper';
import LoadingCellRenderer from 'components/LoadingCellRenderer';
import { postSearchApi } from 'api/chat';


const EmployeesTab = (props) => {
  const { name, website, businessDescription, businessDetails, activities, employees, setEmployees, tabType } = props;
  const DESCRIBE = 'DESCRIBE';
  const DESIGN = 'DESIGN';

  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [agGridContext, setAgGridContext] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [contextMenu, setContextMenu] = useState(null);
  const [isGeneratingEmployees, setIsGeneratingEmployees] = useState(false);
  const [isEstimatingSalaries, setIsEstimatingSalaries] = useState(false);
  const [isGenerating, setIsGenerating] = useReducer(listReducer, []);
  const [validationMessage, setValidationMessage] = useState('');
  const prevRowDataRef = useRef();

  useEffect(() => {
    setAgGridContext({ isLoading: isGenerating,  prevRowData: prevRowDataRef.current });
    prevRowDataRef.current = employees;
  }, [employees, isGenerating]); 

  const numberComparator = (valueA, valueB, nodeA, nodeB, isInverted) => {
    let numA = parseFloat(valueA);
    let numB = parseFloat(valueB);

    if (isNaN(numA)) {
      numA = 0;
    }
    if (isNaN(numB)) {
      numB = 0;
    }

    return numA - numB;
  };

  useEffect(() => { 
    const updatedEmployees = employees.map(employee => {
      if (!employee.id) {
        return { ...employee, id: generateuniqueIshId() };
      }
      if (!employee.fte) {
        return { ...employee, fte: 1 };
      }
      return employee;
    });
  
    // Only update state if changes were made
    if (employees.some(employee => !employee.id || !employee.fte)) {
      setEmployees({ type: 'overwrite', payload: updatedEmployees });
    }
  }, [employees, setEmployees]);

  function isFteValueValid(params) {
    // Check if the value is a number and is greater than zero
    const isValidFte = !isNaN(params.value) || Number(params.value) > 0;
    if (isValidFte) {
      return false;
    } else {
      setValidationMessage(`Invalid FTE number for employee: ${params.data.employee_number}`);
      return true;
    }
  }

  function isSalaryValueValid(params) {
    const salary = Number(params.value);
    const isValidSalary = !isNaN(salary) && salary >= 0;
    
    if (!isValidSalary) {
      setValidationMessage(`Invalid Salary number for employee: ${params.data.employee_number}`);
      return true;
    }
    return false;
  }

  const SalaryCellRenderer = (params) => {
    if (isNaN(params.value) || params.value < 0) {
      return <span className="validation-error">0</span>;
    }
  
    // Format salary as EUR currency
    const formattedSalary = new Intl.NumberFormat('en-IE', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2,
    }).format(params.value);
  
    return <span>{formattedSalary}</span>;
  };

  const baseColumnConfig = {
    editable: true,
    sortable: true,
    filter: true,
    resizable: true,
    autoHeight: true,
  }

  const kpiColumnConfig = {
    editable: false,
    sortable: true,
    resizable: true,
    filter: 'agNumberColumnFilter',
    filterParams: {
      filterOptions: ['lessThan', 'greaterThan', 'equals', 'notEqual'],
    },
    comparator: numberComparator
  }

  const columns = [
    { headerName: 'Employee Number', 
      field: 'employee_number', 
      cellEditor: 'agTextCellEditor',
        cellEditorParams: {
            maxLength: 20
        },
      ...baseColumnConfig,
      cellRendererFramework: LoadingCellRenderer,
      cellClassRules: {
        'validation-error': params => detectDuplicateEmployeeNumber(params),
      },
    },
    { headerName: 'First name', 
      field: 'first_name', 
      cellRendererFramework: LoadingCellRenderer, 
      cellEditor: 'agTextCellEditor',
      cellEditorParams: {
          maxLength: 20
      },
      ...baseColumnConfig },
    {
      headerName: 'Last Name', 
      field: 'last_name', 
      cellEditor: 'agTextCellEditor',
      cellEditorParams: {
          maxLength: 20
      },
      ...baseColumnConfig,
      cellRendererFramework: LoadingCellRenderer,
      cellClassRules: {
        'validation-error': params => !params.value,
      },
    },
    {
      headerName: 'Email',
      field: 'email',
      cellEditor: 'agTextCellEditor',
        cellEditorParams: {
            maxLength: 20
        },
      ...baseColumnConfig,
      cellRendererFramework: LoadingCellRenderer,
      cellClassRules: {
        'validation-error': params => !validateEmail(params.value) || validateUniqueEmail(params),
      },
    },
    { headerName: 'Role', field: 'role', cellRendererFramework: LoadingCellRenderer, ...baseColumnConfig },
    { headerName: 'Unit', field: 'organization_unit', cellRendererFramework: LoadingCellRenderer, ...baseColumnConfig },
    {
      headerName: 'Salary',
      field: 'salary',
      cellRendererFramework: SalaryCellRenderer,
      cellEditor: 'agTextCellEditor',
      cellEditorParams: {
        maxLength: 20,
      },
      ...baseColumnConfig,
      cellClassRules: {
        'validation-error': params => isSalaryValueValid(params),
      },
    },
    { headerName: 'FTE', 
      field: 'fte', 
      cellRendererFramework: LoadingCellRenderer,
      cellEditor: 'agTextCellEditor',
        cellEditorParams: {
            maxLength: 20
        },
      ...baseColumnConfig,
      cellClassRules: {
        'validation-error': params => isFteValueValid(params),
      },
    },
    { headerName: 'Reporting to', 
      field: 'reporting_to', 
      cellClassRules: {
        'validation-error': params => validateReportingTo(params),
      }, 
      cellRendererFramework: LoadingCellRenderer,
      cellEditor: 'agTextCellEditor',
        cellEditorParams: {
            maxLength: 20
        },
      ...baseColumnConfig },
    { headerName: '', field: 'delete_row', cellRenderer: params => <DeleteRowButtonRenderer {...params} employees={employees} setEmployees={setEmployees} />, cellClass: 'delete-row-button-cell', width: 50 }
  ];

  const validateReportingTo = (params) => {
    const employeeId = params.data.employee_number;
    const reportingTo = params.value;
    if (isGenerating.includes(reportingTo)) {
      return false;
    }
    let employeeValidated = false;
    let listOfBlanks = [];
  
    // Map for employee lookup by key supporting both ID and email
    const employeesMap = new Map();
    params.api.forEachNode((node) => {
      employeesMap.set(String(node.data.employee_number), node.data);
      if (node.data.email) {
        employeesMap.set(node.data.email, node.data);
      }
    });
  
    const findEmployeeByReportingTo = (reportingValue) => {
      return employeesMap.get(String(reportingValue));
    };

    if (reportingTo && !findEmployeeByReportingTo(reportingTo)) {
      setValidationMessage(employeeId + ' is reporting to ' + reportingTo + ' but ' + reportingTo + ' does not exist.');
      return true;
    }

    const isCyclic = (startId, visited = new Set()) => {
      if (visited.has(startId)) {
        setValidationMessage('Cyclic relationships detected: ' + [...visited].join(' -> '));
        return false; // Cycle detected
      }
  
      visited.add(startId);
  
      const currentEmployee = findEmployeeByReportingTo(startId);
      if (!currentEmployee || !currentEmployee.reporting_to) {
        return true; // Reached a leaf node
      }
  
      const reportingEmployee = findEmployeeByReportingTo(currentEmployee.reporting_to);
      if (reportingEmployee) {
        return isCyclic(reportingEmployee.employee_number, new Set(visited));
      }
  
      return true;
    };
  
    employeeValidated = isCyclic(employeeId);
    
    if (!employeeValidated) {
      return true;
    }
  
    params.api.forEachNode((node) => {
      if (node.data.reporting_to === '') {
        listOfBlanks.push(node.data.employee_number);
      }
    });
  
    // Invalid if more than one blank or cyclic/reportingTo does not exist
    if (listOfBlanks.length > 1 && !reportingTo) {
      setValidationMessage('Only one employee should have a blank "Reporting To" value.');
      return true;
    }

    return false;
  };
  

  const validateEmail = (email) => {
    if (isGenerating.includes(email)) {
      return true;
    }
    if (!email) {
      setValidationMessage('Email is required.');
      return false;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  function validateUniqueEmail(params) {
    const allEmails = []; // This should be populated from the grid's data source
    params.api.forEachNode(node => allEmails.push(node.data.email));

    // Count occurrences of current email value
    const count = allEmails.filter(email => email === params.data.email).length;
    if (count > 1) {
      setValidationMessage(`Duplicate email: ${params.data.email}`);
      return true;
    }
    return false;
  }

  function detectDuplicateEmployeeNumber(params) {
    const allEmployeeNumbers = [];
    params.api.forEachNode(node => allEmployeeNumbers.push(node.data.employee_number));

    // Count occurrences of current email value
    const count = allEmployeeNumbers.filter(employeeId => employeeId === params.data.employee_number).length;
    if (count > 1) {
      setValidationMessage(`Duplicate employee number: ${params.data.employee_number}`);
      return true;
    }
    return false;
  }

  const rowData = useMemo(() => {
    if (Array.isArray(employees)) {
      return employees.map(employee => ({
        id: employee.id,
        employee_number: employee.employee_number,
        first_name: employee.first_name,
        last_name: employee.last_name,
        email: tabType === DESIGN ? employee.email_address : employee.email || '',
        role: tabType === DESIGN ? employee.title : employee.role || '',
        organization_unit: tabType === DESIGN ? employee.department : employee.organization_unit || '',
        salary: employee.salary || 0,
        reporting_to: employee.reporting_to,
        fte: employee.fte ? employee.fte : 1
      }));
    }
    return [];
  }, [employees, tabType]);
  

  const onGridReady = useCallback(params => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  }, []);

  const validatePercentage = (value) => {
    const number = parseFloat(value);
    return !isNaN(number) && number >= 0 && number <= 100;
  };

  const onCellValueChanged = useCallback(params => {
    setValidationMessage('');
    const updatedEmployees = employees.find(employee => employee.id === params.data.id);
    let field = params.colDef.field;
    if (tabType === DESIGN) {
      if (field === 'email') {
        field = 'email_address';
      }
      if (field === 'role') {
        field = 'title';
      }
      if (field === 'organization_unit') {
        field = 'department';
      }
    }

    const newEmployee = { 
      ...updatedEmployees, 
      email: tabType === DESIGN ? updatedEmployees.email_address : updatedEmployees.email || '',
      role: tabType === DESIGN ? updatedEmployees.title : updatedEmployees.role || '',
      organization_unit: tabType === DESIGN ? updatedEmployees.department : updatedEmployees.organization_unit || '',
      [field]: params.newValue };
    setEmployees({ type: 'update', payload: newEmployee });
  }, [setEmployees, employees]);

  const onCellEditingStarted = useCallback(() => {
    setIsEditing(true);
  }, []);
  
  const onCellEditingStopped = useCallback(() => {
    setIsEditing(false);
  }, []);

  const onCellFocused = useCallback((params) => {
    if (!isEditing) {
      gridApi.stopEditing();
    }
  }, [isEditing, gridApi]);

  const addNewRow = () => {
    const newRow = {
      id: generateuniqueIshId(),
      employee_number: '',
      first_name: '',
      last_name: '',
      email: '',
      role: '',
      organization_unit: '',
      fte: 1,
      reporting_to: ''
    };

    if (DESIGN === tabType) {
      newRow.group = 'gr';
    }
  
    setEmployees({type: 'prepend', payload: [newRow] });
    return newRow;
  };

  const DeleteRowButtonRenderer = ({ api, node, data }) => {
    const handleDelete = () => {
      const updatedEmployees = employees.filter(employee => employee.id !== data.id);
      setEmployees({ type: 'overwrite', payload: updatedEmployees });
    };
  
    return (
      <span onClick={handleDelete}>
        <SVG
          icon="delete.svg#delete"
          iconWidth={24}
          iconHeight={24}
          iconClassName="button__icon button__icon-red"
        />
      </span>
    );
  };

  const generateSyntheticEmployees = async () => {
    if (isGeneratingEmployees) return;
    setIsGeneratingEmployees(true);
    const blankEmployee = addNewRow();
    blankEmployee.email = blankEmployee.id
    blankEmployee.last_name = blankEmployee.id
    blankEmployee.reporting_to = blankEmployee.id
    setIsGenerating({ type: 'add_to_list', payload: blankEmployee.id });

    const apiPayload = {
        'prompt_template': 'generate_employees_list',
        'business_name': name ? name : '', 
        'business_website': website ? website : '',
        'business_description': businessDescription ? businessDescription : '',
        'industry': businessDetails?.industry ? businessDetails.industry : '',
        'number_of_employees': businessDetails?.num_employees ? businessDetails.num_employees : '',
        'activities': activities ? activities : [],
        'employees': employees ? employees : [],
    };

    try {
      const apiResponse = await postSearchApi(apiPayload);
      const generatedEmployees = apiResponse.message[0]['employees'];

      try {
        setEmployees({ type: 'prepend', payload: generatedEmployees } );
      } catch (error) {
          console.error('Error parsing API response:', error);
      }
    } catch (error) {
        console.error('Error sending chat message:', error);
    }
    setIsGeneratingEmployees(false);
    setIsGenerating({ type: 'remove_from_list', payload: blankEmployee.id });
    setEmployees({ type: 'remove', payload: blankEmployee });
  };

  const generateDirectReports = async (employee) => {
    setIsGenerating({ type: 'add_to_list', payload: employee.id });

    const apiPayload = {
        'prompt_template': 'generate_direct_reports',
        'employee': employee,
        'employees': employees,
        'business_name': name ? name : '', 
        'business_website': website ? website : '',
        'industry': businessDetails?.industry ? businessDetails.industry : '',
        'activities': activities ? activities : [],
        'max_employee_number': employees.reduce((max, employee) => Math.max(max, employee.employee_number), 0),
    };

    try {
      const apiResponse = await postSearchApi(apiPayload);
      
      let newEmployees = apiResponse.message[0]['employees'];
      if (tabType === DESIGN) {
        newEmployees = apiResponse.message[0]['employees'].map(employee => {
          
          return {
            id: generateuniqueIshId(),
            employee_number: employee.employee_number,
            first_name: employee.first_name,
            last_name: employee.last_name,
            email: generateEmail(employee),
            email_address: generateEmail(employee),
            role: employee.role,
            title: employee.role,
            organization_unit: employee.organization_unit,
            department: employee.organization_unit,
            fte: employee.fte,
            reporting_to: employee.reporting_to,
          }
        });
      }
      
      return newEmployees;
    } catch (error) {
      console.error('Error sending chat message:', error);
    } 
    setIsGenerating({ type: 'remove_from_list', payload: employee.id });
    
  };

  const EstimateEmployeeSalary = async () => {
    if (isGeneratingEmployees || isEstimatingSalaries) return;
    setIsEstimatingSalaries(true);

    const apiPayload = {
        'prompt_template': 'estimate_salaries',
        'business_name': name ? name : '', 
        'business_website': website ? website : '',
        'industry': businessDetails?.industry ? businessDetails.industry : '',
        'employees': employees ? employees : [],
    };

    try {
      const apiResponse = await postSearchApi(apiPayload);
      const salaries = apiResponse.message;
      const updatedEmployees = employees.map(employee => {
        const salary = salaries.find(salary => salary.employee_number === employee.employee_number);
        return {
          ...employee,
          salary: salary ? salary.salary : 0,
        };
      }
      );

      try {
        setEmployees({ type: 'overwrite', payload: updatedEmployees } );
      } catch (error) {
          console.error('Error parsing API response:', error);
      }
    } catch (error) {
        console.error('Error reaching servers:', error);
    }
    setIsEstimatingSalaries(false);
  };

  const generateEmail = (employee) => {
    return employee.first_name.replace(/\s+/g, '') + '.' + employee.last_name.replace(/\s+/g, '') + '@reconfig.ai';
  };

  const [showImport, setShowImport] = useState(false);
  const [showintergation, setShowIntegration] = useState(false);

  const handleImportSubmit = useCallback((data) => {
    setShowImport(false);
    let newEmployees = [];
    data.rows.forEach((row) => {
      let a = {
        id: generateuniqueIshId(),
        employee_number: row[data.employee_number],
        first_name: row[data.first_name],
        last_name: row[data.last_name],
        email: row[data.email],
        role: row[data.role],
        organization_unit: row[data.organization_unit],
        fte: row[data.fte],
        reporting_to: row[data.reporting_to],
      }
      if (newEmployees.find(c => c.email === a.email)) {
        return;
      }
      newEmployees.push(a);
    })

    if(data.write_mode === 'extend') {
      newEmployees = [...employees, ...newEmployees];
    }

    setEmployees({ type: data.write_mode, payload: newEmployees });
  }, [employees, setEmployees]);

  const onCellContextMenu = (event) => {
    setContextMenu({
      x: event.event.pageX,
      y: event.event.pageY,
      rowData: event.data,
    });
  };

  const closeContextMenu = () => {
    setContextMenu(null);
  };

  const onContextMenu = (e) => {
    e.preventDefault();
  };  

  return (
    <div className="function-tab">
      <div className="function-tab-header">
      </div>
      <Row xs="8" className="align-items-left">
        <Col>
          { tabType === DESCRIBE && (
            <button onClick={() => generateSyntheticEmployees()} disabled={ isGeneratingEmployees } className="button ms-auto">
            { employees.length !== 0 ? 'Generate more Employees' : 'Generate Employees' }
              <span className="material-symbols-outlined">
              neurology
              </span>
            </button>
          )}
          <button onClick={() => setShowImport(true)} className="button ms-auto">
            Import Employees
            <span className="material-symbols-outlined">
              upload
            </span>
          </button>
          <Modal isOpen={showImport} toggle={() => setShowImport(false)}>
            <ModalHeader>Import Employee list</ModalHeader>
            <CSVWizard
              toggle={() => setShowImport(false)}
              handleSubmit={handleImportSubmit}
              specifyColumns={[
                { key: 'employee_number', label: 'Employee number', placeholder: 'Employee number' },
                { key: 'first_name', label: 'First name', placeholder: 'First name', },
                { key: 'last_name', label: 'Last name', placeholder: 'Last name', required: true },
                { key: 'email', label: 'Email', placeholder: 'Email', required: true},
                { key: 'role', label: 'Role', placeholder: 'Role' },
                { key: 'organization_unit', label: 'Unit', placeholder: 'Organization unit' },
                { key: 'fte', label: 'FTE', placeholder: 'FTE', required: true, default: 1},
                { key: 'reporting_to', label: 'Reporting to', placeholder: 'Reporting to' },
              ]}
            />
          </Modal>
          <ExportToExcel 
            buttonLabel='Export Employees' 
            data={employees} 
            fileName={name + ' Employee list from Reconfig'} 
            sheetName={name + ' Employee list'} 
            fieldsToExport={['employee_number', 'first_name', 'last_name', 'email', 'role', 'organization_unit', 'reporting_to', 'fte']}
          />
          { tabType === DESCRIBE && (
            <button onClick={() => setShowIntegration(true)} className="button ms-auto">
            Connect to HR system
            <span className="material-symbols-outlined">
              database
            </span>
            </button>
          )}
          <Modal isOpen={showintergation} toggle={() => setShowIntegration(false)}>
            <ModalHeader>Load employee data from your internal systems</ModalHeader>
            <ModalBody>
              <div className="d-flex w-100">
                  <h4>The Reconfig team can help you connect to your internal systems to load employee data. Please contact us to set up a connection with your internal systems: <a href="mailto:contact@reconfig.no">contact@reconfig.no</a></h4>
                  <div className="d-flex align-items-end" style={{ marginTop: '75px' }}>
                    <button style={{ maxHeight: 40 }} className="button" onClick={() => setShowIntegration(false)}>
                      Close
                    </button>
                  </div>
              </div>
            </ModalBody>
          </Modal>
          <button 
            onClick={() => EstimateEmployeeSalary()} 
            disabled={isEstimatingSalaries}
            className="button ms-2" 
          >
              Estimate salaries
            {isEstimatingSalaries ? (
              <Loading style={{ marginLeft: 'auto', height: '35px' }} />
            ) : (
              <span className="material-symbols-outlined">neurology</span>
            )}
          </button>
          <Button
          color="primary"
          onClick={addNewRow}
          text="Add employee"
          className="button ms-auto"
          icon="plus.svg#plus"
          />
        </Col>
        { validationMessage && (
          <Col>
            <div className="validation-error">{validationMessage}</div>
          </Col>
        )}
      </Row>
      {/*isGenerating ? (
            <div className="loading-spin">
              <Loading text="Searching public registries for employee data..." />
            </div>
          ) : (
            ''
      )*/}
      <div className="mt-2 ag-theme-alpine ag-row grid-height-400" onContextMenu={onContextMenu}> 
        <AgGridReact
          columnDefs={columns}
          rowData={rowData}
          onGridReady={onGridReady}
          context={ agGridContext }
          onCellValueChanged={onCellValueChanged}
          onCellEditingStarted={onCellEditingStarted}
          onCellEditingStopped={onCellEditingStopped}
          onCellContextMenu={onCellContextMenu}
          onCellFocused={onCellFocused}
        />
      </div>
      {contextMenu && (
            <AggridContextMenu
              buttonLabel="Generate direct reports"
              x={contextMenu.x}
              y={contextMenu.y}
              onClose={closeContextMenu}
              onGenerate={() => {
                generateDirectReports(contextMenu.rowData).then(directReports => {
                  const manager = {...contextMenu.rowData, fte: '1', department: contextMenu.rowData.organization_unit, title: contextMenu.rowData.role, email_address: generateEmail(contextMenu.rowData)};
                  setEmployees({type: 'replace', payload: {old: contextMenu.rowData, new: [manager, ...directReports]}});
                  setIsGenerating({ type: 'remove_from_list', payload: manager.id });
                  closeContextMenu();
                }).catch(error => {
                  console.error('Error geerating direct reports: ', error);
                });
              }}
              />
        )}
    </div>
  );
}

export default EmployeesTab;

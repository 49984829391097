import React, { Component, createRef } from 'react';
import TextInput from 'components/TextInput';
import HTMLEditor from 'components/HTMLEditor';

export default class EmailEditor extends Component {
  constructor(props) {
    super(props);
    this.htmlEditorRef = createRef();
    this.state = { ...props.data };
  }

  getContent() {
    const editor = this.htmlEditorRef.current;
    return {
      subject: this.state.subject,
      reply: this.state.reply,
      from_name: this.state.from_name,
      body: editor?.getContent?.() || '', // Safeguard against null
    };
  }

  onChange = (event) => {
    const { name, value } = event.target;
    if (this.state[name] !== value) {
      this.setState({ [name]: value }, () => {
        this.props.onChange?.(this.getContent());
      });
    }
  };

  onChangeHtml = (editorValue) => {
    this.props.onChange?.({
      ...this.getContent(),
      body: editorValue,
    });
  };

  componentDidMount() {
    const { body } = this.state;
    if (this.htmlEditorRef.current?.setContent) {
      this.htmlEditorRef.current.setContent(body);
    }
  }

  render() {
    const { subject, reply, body, from_name } = this.state;
    const { readOnly } = this.props;

    return (
      <div className="email-editor">
        <TextInput
          label="Subject"
          name="subject"
          value={subject || ''}
          onChange={this.onChange}
          disabled={readOnly}
        />
        <TextInput
          label="Reply To"
          name="reply"
          value={reply || ''}
          onChange={this.onChange}
          disabled={readOnly}
        />
        <TextInput
          label="From Name"
          name="from_name"
          value={from_name || ''}
          onChange={this.onChange}
          disabled={readOnly}
        />
        <h4 className="me-3">Email body</h4>
        {readOnly ? (
          <div className="mb-3 mt-3" dangerouslySetInnerHTML={{ __html: body }} />
        ) : (
          <HTMLEditor
            ref={this.htmlEditorRef}
            name="body"
            value={body}
            onChange={this.onChangeHtml}
          />
        )}
      </div>
    );
  }
}

export const validateAgGridColumn = (params, fieldName) => {
    if (!params.newValue || params.newValue.trim() === '') {
      params.data[fieldName] = ''; // Set the field to an empty string if validation fails
      return false; // Prevent AgGrid from updating the value
    }
    params.data[fieldName] = params.newValue.trim(); // Trim and set the new value
    return true; // Allow AgGrid to update the value
  };
  
export const validateFunctions = (functionList) => {
  return functionList.every((func) => {
    return (
      func.name && func.name.trim() !== '' &&
      func.description && func.description.trim() !== '' &&
      func.strategy_link && func.strategy_link.trim() !== ''
    );
  });
};

export const validateOrganizationUnits = (unitList) => {
  return unitList.every((unit) => {
    return (
      unit.name && unit.name.trim() !== '' &&
      unit.abbreviation && unit.abbreviation.trim() !== '' &&
      unit.purpose && unit.purpose.trim() !== ''
    );
  });
  
};

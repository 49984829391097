import React, { PureComponent } from 'react';
import classNames from 'classnames';

export default class ToggleButton extends PureComponent {
  handleClick = (e) => {
    if (this.props.disabled) {
      return;
    }
    let checked = !this.props.checked;
    if (this.props.onChange) {
      this.props.onChange(e, checked, this.props.value);
    }
  };

  render() {
    let { id, value, className, text, disabled } = this.props;
    const btnCls = classNames({
      btn: true,
      'btn-selected': this.props.checked,
      'btn-unselected': !this.props.checked,
      [className]: !!className,
    });

    text = text || value;

    return (
      <button
        id={id}
        value={value}
        className={btnCls}
        type="button"
        aria-pressed={this.props.checked}
        onClick={this.handleClick}
        disabled={disabled}
      >
        {text}
      </button>
    );
  }
}

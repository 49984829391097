import React, { useEffect, useState } from 'react';

const AggridContextMenuOption = ({ buttonLabel, x, y, onClose, onGenerate }) => {
  
  // Close the menu if clicked outside
  useEffect(() => {
    const closeMenu = () => onClose();
    window.addEventListener('click', closeMenu);
    return () => window.removeEventListener('click', closeMenu);
  }, [onClose]);

  return (
    <div
      className="activity-table-context-menu"
      style={{
        position: 'fixed',
        top: y,
        left: x,
        zIndex: 100,
      }}
    >
      <ul style={{ listStyle: 'none', margin: 0, padding: 0 }}>
        <li className='button' onClick={onGenerate}>
          {buttonLabel}
        </li>
        {/* Add more menu items here */}
      </ul>
    </div>
  );
};

export default AggridContextMenuOption;
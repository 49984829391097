import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const HTMLEditor = forwardRef(({ value, onChange, placeholder }, ref) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [isFocused, setIsFocused] = useState(false);
  const isMounted = useRef(false); // Prevents unnecessary reinitialization

  // Expose getContent() and setContent() to parent component
  useImperativeHandle(ref, () => ({
    getContent: () => draftToHtml(convertToRaw(editorState.getCurrentContent())),
    setContent: (html) => {
      if (!html) {
        setEditorState(EditorState.createEmpty());
        return;
      }
      const contentBlock = htmlToDraft(html);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        setEditorState(EditorState.createWithContent(contentState));
      }
    },
  }));

  // Handle external value changes
  useEffect(() => {
    if (isFocused) return;
    if (value && !isMounted.current) {
      const contentBlock = htmlToDraft(value);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        setEditorState(EditorState.createWithContent(contentState));
      }
    } else if (value && isMounted.current) {
      const contentBlock = htmlToDraft(value);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        setEditorState(EditorState.createWithContent(contentState));
      }
    } else {
      setEditorState(EditorState.createEmpty());
    }
  }, [value]);

  useEffect(() => {
    isMounted.current = true;
  }, []);

  const handleBlur = () => {
    setIsFocused(false);
    const htmlContent = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    onChange?.(htmlContent);
  };

  return (
    <div>
      <Editor
        editorState={editorState}
        placeholder={placeholder}
        wrapperClassName="draft-html-editor-wrapper"
        editorClassName="draft-html-editor"
        onEditorStateChange={setEditorState}
        onFocus={() => setIsFocused(true)}
        onBlur={handleBlur}
        toolbar={{
          inline: { inDropdown: true },
          list: { inDropdown: true },
          textAlign: { inDropdown: true },
          link: { inDropdown: true },
          history: { inDropdown: true },
        }}
      />
    </div>
  );
});

export default HTMLEditor;

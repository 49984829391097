import React, { Component, useState } from 'react';
import {
  FormFeedback,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import {
  getSize,
  parseImportance,
  parseImportanceInt,
  toImportance,
} from 'lib/helper';
import {
  getCoordinationLoadByCluster,
  getProductivityGain,
  getRelationshipWorkEffort,
  getSubMembers,
} from 'lib/metrics';
import _ from 'lib/lodashFunctions';
import classNames from 'classnames';
import {
  ActivitySelected,
  ActivitySelector,
} from 'components/ActivitySelector';
import CellNode from 'components/CellNode';

function DropDownOfField({ field, state, onChanged, error, isDisabled }) {
  return (
    <div className="row">
      <div className="col-md-4">
        <b>{field.label} {field.required && !isDisabled ? <span className='text-danger'>*</span> : ''}: </b>
      </div>
      <div className="col-md-8">
        <Dropdown
          list={['None', ...field.options]}
          index={state[field.field]?.index}
          className="dropdown"
          menuClassName="modal-dropdown"
          onChangeItem={(idx, label) => onChanged(field, idx, label)}
          disabled={isDisabled}
          error={error}
        />
        {
          error
            ? <FormFeedback>{error}</FormFeedback>
            : ''
        }
      </div>
    </div>
  );
}

const MetricField = ({ label, value, unit }) => {
  return (
    <FormGroup row>
      <Label md={4} for={label}>
        <b>{label}: </b>
      </Label>
      <Label md={8}>
        {value} {unit}
      </Label>
    </FormGroup>
  );
}

const MetricSliderField = ({ label, link, unit, onValueChange, error, isDisabled }) => {
  const numFTEInvolved = link ? link.num_fte_involved : 2;
  const [currentValue, setCurrentValue] = useState(numFTEInvolved);

  const handleSliderChange = (e) => {
    setCurrentValue(e.target.value);
    onValueChange && onValueChange(e.target.value);
  };

  return (
    <div className="row">
      <div className="col-md-4">
        <b>{label}: </b>
      </div>

      {!isDisabled ? (
        <div className="col-md-8 d-flex">
          <input
            type="range"
            min="0"
            max="100"
            value={currentValue}
            onChange={handleSliderChange}
            disabled={isDisabled}
            className={`metricSlider ${error ? 'error-class' : ''}`}
          />
          <div className="dropdown">
            {currentValue} {unit}
          </div>
          {
            error
              ? <FormFeedback>{error}</FormFeedback>
              : ''
          }
        </div>
      ) : (
        <div className="col-md-8">
          <div className="dropdown">
            {currentValue} {unit}
          </div>
        </div>
      )
      }
    </div>
  );
};


export default class EditCellModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      value: {},
      fteInvolved: 2,
    };
    const { data, threshold } = props;

    this.options = _.groupBy(threshold, 'field');
    this.fields =
      props.survey_type === 1
        ? [
          {
            field: 'permanence',
            fields: ['permanence'],
            label: 'Relationship stability',
            options: props.threshold
              .filter((th) => th.field === 'permanence')
              .map((t) => t.title),
            raw: props.threshold.filter((th) => th.field === 'permanence'),
          },
          {
            field: 'frequency',
            label: 'Interaction frequency',
            fields: ['frequency'],
            options: props.threshold
              .filter((th) => th.field === 'frequency')
              .map((t) => t.title),
            raw: props.threshold.filter((th) => th.field === 'frequency'),
          },
          {
            field: 'duration',
            label: 'Interaction duration',
            fields: ['duration'],
            options: props.threshold
              .filter((th) => th.field === 'duration')
              .map((t) => t.title),
            raw: props.threshold.filter((th) => th.field === 'duration'),
          },
          {
            field: 'value',
            label: 'Relationship importance',
            fields: ['weight'],
            options: props.threshold
              .filter((th) => th.field === 'weight')
              .map((t) => t.title),
          },
        ]
        : [
          {
            field: 'value',
            label: 'Relationship importance',
            fields: ['weight'],
            options: props.threshold
              .filter((th) => th.field === 'weight')
              .map((t) => t.title),
          },
        ];

    this.fieldMap = _.keyBy(this.fields, (g) => g.field);
    this.nodeMap = _.keyBy(data.nodes, (n) => n.id);
    this.fieldValueMap = {
      'frequency': {
        'Daily': 20,
        'A few times a week': 10,
        'Once a week': 4,
        'A few times a month': 2,
        'Once a month or less frequently': 1,
      },
      'duration': {
        'Less than 1 hour': 1,
        '1-2 hours': 2,
        '2-4 hours': 4,
        'Up to a full day or longer': 8,
      },
    };
    this.onDropdownChanged = this.onDropdownChanged.bind(this);
    this.onMetricSliderChanged = this.onMetricSliderChanged.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  connectionRatio = () => {
    const { data, nodeFrom, nodeTo } = this.state;
    if (!data) {
      return null;
    }

    const nSource = getSize(nodeFrom, this.nodeMap);
    let nTarget = getSize(nodeTo, this.nodeMap);
    if (data.source === data.target) nTarget--;
    let percent = (data.count * 100) / (nSource * nTarget);
    if (data && nSource * nTarget > 1) {
      const ratio = `${data.count}/${nSource * nTarget} ${percent.toFixed(1)}%`;
      return (
        <div className="filter mb-4 row">
          <div className="ps-3 col-md-4">
            <b>Connection ratio:</b>
          </div>
          <div className="col-md-8">{ratio}</div>
        </div>
      );
    } else {
      return null;
    }
  };

  getAmbiguity = (links) => {
    if (!links?.length) return 0;

    return (
      links.filter((link) => link.reportedBy?.length === 1).length /
      links.length
    ).toFixed(2);
  };

  ambiguity = () => {
    if (!this.state.data) {
      return null;
    }
    const nSource = getSize(this.state.nodeFrom, this.nodeMap);
    let nTarget = getSize(this.state.nodeTo, this.nodeMap);
    if (this.state.data.source === this.state.data.target) nTarget--;
    if (nSource * nTarget > 1) {
      const ambiguity = this.getAmbiguity(this.state.data.links);

      if (!ambiguity) return null;

      return (
        <div className="filter mb-4 row">
          <div className="ps-3 col-md-4">
            <b>Ambiguity:</b>
          </div>
          <div className="col-md-8">{ambiguity}</div>
        </div>
      );
    } else {
      return null;
    }
  };

  isGroup = () => {
    if (!this.state.data) {
      return false;
    }
    const nSource = getSize(this.state.nodeFrom, this.nodeMap);
    let nTarget = getSize(this.state.nodeTo, this.nodeMap);
    if (this.state.data.source === this.state.data.target) nTarget--;
    return nSource * nTarget > 1;
  };

  showModal = (nodeFrom, nodeTo, link, editable, data) => {
    // Used on parent component
    if (!editable && !link) {
      return;
    }
    this.nodeFrom = nodeFrom;
    this.nodeTo = nodeTo;
    this.link = link;
    link = data || link || {};

    let activities = link.relationship ? [...link.relationship] : [];

    let value = link.value || 0;
    let reportedBy;
    if (link.reported_by?.[0] === 'stamp') {
      reportedBy = ['Manual data edit'];
    } else if (Array.isArray(link.reported_by)) {
      reportedBy = link.reported_by.map((u) => this.nodeMap[u]?.name).filter(u => !!u)
    }
    let state = {};
    for (let field of this.fields) {
      state[field.field] = {
        index: parseImportanceInt(
          link[field.field],
          this.props.threshold,
          field.fields
        ),
        item: parseImportance(
          link[field.field],
          this.props.threshold,
          field.fields
        ),
      };
    }
    state = {
      modal: true,
      ...state,
      activities: activities.map((activity) => ({
        value: activity,
        label: this.props.activityMap[activity],
      })),
      activityDisabled: value === 0,
      reportedBy,
      editable,
      nodeFrom,
      nodeTo,
      data,
      workEffort: link.value_new || 0,
      totalWorkEffort: link.value_new * link.num_fte_involved,
      error: null,
      fteInvolved: link.num_fte_involved || 2,
      valid: null,
    }

    state.valid = this.checkValid(state);

    this.setState(state);
  };

  onSave = () => {
    if (this.state.valid) {
      this.setState({ error: 'Make sure both Relationship work effort and Activities are selected' });
      return;
    }
    if (
      this.state.value.item === 'None' ||
      !this.state.activities ||
      this.state.activities.length === 0
    ) {
      if (this.link) {
        this.props.data.links.forEach(link => link.edited = undefined);
        this.link.edited = 'deleted';
        this.link.value = 0;
      }
    } else if (this.link) {
      for (let field of this.fields) {
        this.link[field.field] = toImportance(
          this.state[field.field].item,
          this.props.threshold,
          field.fields
        );
      }
      this.link.value_new = this.state.workEffort;
      this.link.num_fte_involved = this.state.fteInvolved;
      this.link.relationship = this.state.activities.map(
        (activityOption) => activityOption.value
      );
      this.props.data.links.forEach(link => link.edited = undefined);
      this.link.edited = 'edited';
    } else {
      let state = {};
      for (let field of this.fields) {
        state[field.field] = toImportance(
          this.state[field.field].item,
          this.props.threshold,
          field.fields
        );
      }

      this.props.data.links.forEach(link => link.edited = undefined);
      this.props.data.links.push({
        sourceId: this.nodeFrom.id,
        targetId: this.nodeTo.id,
        source: this.nodeFrom,
        target: this.nodeTo,
        ...state,
        value_new: this.state.workEffort,
        num_fte_involved: this.state.fteInvolved,
        relationship: this.state.activities.map(
          (activityOption) => activityOption.value
        ),
        edited: 'added',
      });
    }
    this.toggle();
    if (this.props.onConfirm) this.props.onConfirm();
  };

  onActivityChange = (options) => {
    const newState = {
      ...this.state,
      activities: options || [],
    }
    newState.valid = this.checkValid(newState);
    newState.error = null;
    this.setState(newState);
  };

  onDropdownChanged = (field, idx, item) => {
    const newState = {
      ...this.state,
      [field.field]: {
        index: idx,
        item: item,
      },
    };
    const duration = newState['duration'].item !== '' ? +this.fieldValueMap['duration'][newState['duration'].item] : 0;
    const frequency = newState['frequency'].item !== '' ? +this.fieldValueMap['frequency'][newState['frequency'].item] : 0;
    const fteInvolved = +newState.fteInvolved || 2;
    newState.workEffort = duration * frequency 
    newState.totalWorkEffort = newState.workEffort * fteInvolved;
    newState.valid = this.checkValid(newState);
    newState.error = null;
    this.setState(newState);
  };

  checkValid = (state) => {
    let errors = null;
    if (!state.workEffort) {
      errors = {
        ...errors,
        workEffort: 'Please input work effort',
      }
    }
    if (!state.activities || state.activities.length === 0) {
      errors = {
        ...errors,
        activities: 'Please select activities',
      }
    }
    return errors;
  }

  onDelete = () => {
    this.link.value = 0;
    this.link.value_new = 0;
    this.toggle();
    if (this.props.onConfirm) this.props.onConfirm();
  }

  getValueForLabel(label, props) {
    const { nodeFrom, nodeTo } = this.state;
    const { data, coefficient } = props;
    const { links, nodes, base_result, optimize_type } = data;
    if (label === 'coordination_load') {
      if (!nodeFrom?.ax || !nodeFrom?.id === nodeTo?.id) {
        return null;
      }
      let linkCountInGroup = 0;
      let subMembers = getSubMembers(nodeFrom);
      data.links.forEach(link => {
        if (subMembers.indexOf(link.sourceId) > -1 && subMembers.indexOf(link.targetId) > -1) {
          linkCountInGroup += 1;
        }
      });
      return getCoordinationLoadByCluster(subMembers.length, linkCountInGroup, optimize_type);
    } else if (label === 'productivity_gain') {
      let subMembers = getSubMembers(nodeFrom);
      return getProductivityGain(
        links, nodes, base_result, coefficient, subMembers,
      ).value;
    }
  }

  onMetricSliderChanged = (value) => {
    const duration = this.state.duration.item !== '' ? +this.fieldValueMap['duration'][this.state.duration.item] : 0;
    const frequency = this.state.frequency.item !== '' ? +this.fieldValueMap['frequency'][this.state.frequency.item] : 0;
    const fteInvolved = +value;
    const workEffort = duration * frequency;
    const totalWorkEffort = workEffort * fteInvolved;
    this.setState({ fteInvolved: +value, workEffort: workEffort, totalWorkEffort: totalWorkEffort });
    
  };

  render() {
    const { editable, modal, nodeFrom, nodeTo, fteInvolved } = this.state;
    const { survey_type, data } = this.props;
    if (!modal) return null;

    const relevantLink = data.links.find(
      (link) =>
        link.sourceId === nodeFrom.id && link.targetId === nodeTo.id
    );

    const fteInvolvedValue = fteInvolved || 2;
    const workEffort = this.state.totalWorkEffort || 0;

    return (
      <div>
        <Modal
          isOpen={modal}
          toggle={this.toggle}
          className={classNames('modal-dialog-centered', 'editcellmodal', {
            readonly: !editable,
          })}
        >
          <ModalHeader>{!editable ? 'Relationship details' : 'Edit Relationship'}</ModalHeader>
          <ModalBody className="p-2">
            <div className="row">
              <div className="col-md-6">
                {survey_type === 1 && (
                  <MetricField
                    label="Relationship work effort"
                    value={workEffort.toFixed(1)}
                    unit="h/mth"
                  />
                )}
                <DropDownOfField
                  field={this.fieldMap['value']}
                  state={this.state}
                  onChanged={this.onDropdownChanged}
                  isDisabled={!editable}
                />
                {survey_type === 1 && (
                  <DropDownOfField
                    field={this.fieldMap['permanence']}
                    state={this.state}
                    onChanged={this.onDropdownChanged}
                    isDisabled={!editable}
                  />
                )}
                {survey_type === 1 && (
                  <DropDownOfField
                    field={this.fieldMap['frequency']}
                    state={this.state}
                    onChanged={this.onDropdownChanged}
                    isDisabled={!editable}
                  />
                )}
                {survey_type === 1 && (
                  <DropDownOfField
                    field={this.fieldMap['duration']}
                    state={this.state}
                    onChanged={this.onDropdownChanged}
                    isDisabled={!editable}
                  />
                )}
                {survey_type === 1 && !nodeFrom?.ax && (
                  <MetricSliderField
                    field="num_fte_involved"
                    label="Resources involved"
                    link={relevantLink}
                    unit="resources"
                    onValueChange={this.onMetricSliderChanged}
                    isDisabled={!editable}
                  />
                )}
                {
                  getSubMembers(nodeFrom) &&
                  <>
                    {(nodeFrom?.ax || !nodeFrom?.id === nodeTo?.id) && 
                      <MetricField
                        label="Coordination Load"
                        value={this.getValueForLabel('coordination_load', this.props, nodeFrom, nodeTo)}
                      />
                    }
                    {
                      // <MetricField
                      //   label="Productivity Gain"
                      //   value={this.getValueForLabel('productivity_gain', this.props, nodeFrom, nodeTo).toFixed(2)}
                      //   unit="h/mth"
                      // />
                    }
                  </>
                }

              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-4">
                    <b>From: </b>
                  </div>{' '}
                  <div className="col-md-8">
                    <CellNode node={this.nodeFrom} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <b>To: </b>
                  </div>{' '}
                  <div className="col-md-8">
                    <CellNode node={this.nodeTo} />
                  </div>
                </div>
                {!this.isGroup() && (
                  <div className="filter mb-4 row">
                    <div className="col-md-4">
                      <b>Reported by: </b>
                    </div>
                    <div className="ps-3 col-md-8">
                      {this.state.reportedBy?.map((u) => (
                        <div key={u}>{u}</div>
                      ))}
                    </div>
                  </div>
                )}
                {this.connectionRatio()}
                {this.ambiguity()}
                <div className="filter mb-4 row">
                  <div className="col-md-12">
                    <b>Activities {editable && <span className='text-danger'>*</span>}: </b>
                  </div>
                  <div className={classNames('col-md-12 edit-cell-activity-selector', { 'is-invalid': this.state.error && this.state.valid.activities })}>
                    {editable ? (
                      <ActivitySelector
                        value={this.state.activities}
                        setValue={this.onActivityChange}
                        options={this.props.activityOptions}
                      />
                    ) : (
                      <ActivitySelected
                        value={this.state.activities}
                        options={this.props.activityOptions}
                        filters={this.props.filters}
                      />
                    )}
                  </div>
                  {this.state.error && this.state.valid.activities && <FormFeedback>{this.state.valid?.activities}</FormFeedback>}
                </div>
              </div>
            </div>
            <div className="d-lg-flex align-items-center">
              {editable && (
                <Button
                  text="Delete"
                  onClick={this.onDelete}
                  className="button red"
                />
              )}
              {this.state.valid && this.state.error && <span className='text-danger ms-auto'>{this.state.error}</span>}
              {editable && (
                <Button
                  text="Save"
                  onClick={this.onSave}
                  className="button ms-auto me-3"
                />
              )}
              <Button
                text="Cancel"
                className={`button ${editable ? 'ms-3' : 'ms-auto'}`}
                onClick={this.toggle}
              />
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
